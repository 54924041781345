import {html, LitElement, type TemplateResult} from 'lit';
import {customElement, property, query, state} from 'lit/decorators.js';
import {live} from 'lit/directives/live.js';
import {PubSub, Logger} from '@cad/static-next-lib';

import styles from './multisearch.styles';
import {desktop, tablet} from '../../js/services/const';
import type {Suggest, Suggestions} from './ISuggestions';

@customElement('ui-multisearch')
export class MultiSearch extends LitElement {
  static styles = styles;

  @query('.input__control') input: HTMLInputElement;

  @property() action = '';
  @property() multiSuggest = ''; // if the function is deactivated in the backend, the property remains empty
  @property() brandName = '';
  @property() mobileRegistrationUrl?: string = undefined;

  @property({attribute: 'origin'}) private currentOrigin = 'magazin';

  @property({type: Object}) result?: Suggestions;
  @property({type: Array}) suggestions?: Suggest[];
  @property() show = false;
  @property() selectedMenuIndex = -1;
  @property() userInput = '';

  @state() private abortController = new AbortController();
  @state() private displayInput = '';
  @state() private hasFocus = false;
  @state() private mouseOver = false;
  @state() private suppressFullscreen = false;
  @state() private formAction = '';
  @state() private formMethod: 'get' | 'post' = 'get';

  firstUpdated() {
    this.formAction = this.action;

    if (this.mobileRegistrationUrl) {
      this.sendBrainTrackingEvent('header_registration_show');
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.handleKeyboardDown = this.handleKeyboardDown.bind(this);
    addEventListener('keydown', this.handleKeyboardDown);
  }

  disconnectedCallback() {
    removeEventListener('keydown', this.handleKeyboardDown);
  }

  handleKeyboardDown(event: KeyboardEvent) {
    if (this.show && this.suggestions) {
      if (event.key === 'ArrowUp') {
        if (this.selectedMenuIndex > 0) {
          this.selectedMenuIndex--;
        } else {
          this.selectedMenuIndex = this.suggestions.length - 1;
        }
      }

      if (event.key === 'ArrowDown') {
        if (this.selectedMenuIndex < this.suggestions.length - 1) {
          this.selectedMenuIndex++;
        } else {
          this.selectedMenuIndex = 0;
        }
      }

      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        // update search input field with currently selected input
        const suggestion = this.suggestions[this.selectedMenuIndex];
        this.displayInput = suggestion?.suggest ?? '';

        // update form attributes with suggestion url on arrow key.
        // Form attribute updates include form method because,
        // form behaviour ignores the query parameters in form action when
        // form method is set to GET
        if (suggestion?.url) {
          this.formAction = suggestion.url;
          this.formMethod = 'post';
        } else {
          this.resetFormAttrib();
        }
      }
    }
  }

  handleClearClick(event: MouseEvent) {
    this.userInput = '';
    this.displayInput = '';
    this.selectedMenuIndex = -1;
    this.show = false;
    this.input.focus();
    this.resetFormAttrib();
    event.stopPropagation();
  }

  handleBackClick(event: MouseEvent) {
    this.userInput = '';
    this.displayInput = '';
    this.selectedMenuIndex = -1;
    this.className = '';
    this.suppressFullscreen = true;
    this.resetFormAttrib();
    event.stopPropagation();
  }

  async handleInput() {
    this.userInput = this.input.value;

    if (!this.multiSuggest) {
      return;
    }

    if (this.userInput.length == 0) {
      this.show = false;
      this.selectedMenuIndex = -1;
      this.displayInput = '';
      return;
    }

    this.resetFormAttrib();

    await this.requestSuggestions();

    if (this.result) {
      this.suggestions = this.result.suggests;
      // show only if result contains data
      this.show = this.suggestions.length > 0;
    }

    // reset selected Menu Index after every search
    this.selectedMenuIndex = -1;
    this.displayInput = '';
  }

  handleBlur() {
    this.hasFocus = false;
    this.show = this.mouseOver;
    this.userInput = this.userInput.trim();
  }

  handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.userInput = this.userInput.trim();
    }
  }

  handleDropdownMouseEnter() {
    this.mouseOver = true;
  }

  handleDropdownMouseLeave() {
    this.mouseOver = false;
  }

  handleFocus() {
    this.hasFocus = true;
    this.suppressFullscreen = false;

    if (this.userInput.length > 0 && (this.suggestions && this.suggestions.length > 0)) {
      this.show = true;
    }
  }

  resetFormAttrib() {
    this.formAction = this.action;
    this.formMethod = 'get';
  }

  navigateToRegister() {
    if (this.mobileRegistrationUrl) {
      window.location.href = this.mobileRegistrationUrl;
      this.sendBrainTrackingEvent('header_registration_success');
    }
  }

  openFullscreenSearch() {
    this.className = 'fullscreen';
    this.requestUpdate();
    this.performUpdate();
    this.input.focus();
  }

  render() {
    if (this.hasFocus && this.isMobileViewport() && !this.suppressFullscreen) {
      this.className = 'fullscreen';
    }

    return html`
      ${this.formInputTemplate()}
      ${this.suggestionListTemplate()}
    `;
  }

  formInputTemplate() {
    return html`
      <div class="${this.getFormWrapperClasses()}">
        <form action="${this.formAction}" method="${this.formMethod}">
          ${this.className === 'fullscreen' ? html`
            <button
              type="button"
              @click=${this.handleBackClick}
              tabindex="-1">
              <span class="back-icon"></span>
            </button>
          ` : ''}
          <div class="${this.getHoverWrapperClasses()}">
            <input type="hidden" name="origin" value="${this.currentOrigin}" />
            <input
              class="${this.className === 'fullscreen' ? 'input__control fullscreen' : 'input__control'}"
              type="search"
              maxlength="200"
              aria-label="Sucheingabe"
              name="q"
              autocomplete="off"
              placeholder="${this.isMobileViewport() ? 'Suche' : 'Suchen mit ' + this.brandName}"
              .value="${live(this.displayInput.length > 0 ? this.displayInput : this.userInput)}"
              @input=${this.handleInput}
              @focus=${this.handleFocus}
              @blur=${this.handleBlur}
              @keydown=${this.handleKeyDown}
            />
            ${this.userInput?.length > 0 ? html`
              <button
                class="${this.className === 'fullscreen' ? 'input__clear fullscreen' : 'input__clear'}"
                type="button"
                @click=${this.handleClearClick}
                tabindex="-1">
                <span class="clear-icon"></span>
              </button>
            ` : ''}
            <button
              class="${this.className === 'fullscreen' && this.multiSuggest ? 'input__submit fullscreen' : 'input__submit'}"
              type="submit"
              aria-label="Suchschaltfläche">
              <span class="submit-icon"></span>
            </button>
          </div>
        </form>
      </div>
      ${this.getOptionalSinlgeSearchIconTemplate()}
    `;
  }

  getFormWrapperClasses(): string {
    if (this.className === 'fullscreen') {
      return 'form__wrapper fullscreen';
    } else if (this.mobileRegistrationUrl && this.isMobileViewport()) {
      return 'form__wrapper hidden';
    }
    return 'form__wrapper';
  }

  getOptionalSinlgeSearchIconTemplate() {
    if (this.mobileRegistrationUrl && this.className !== 'fullscreen' && this.isMobileViewport()) {
      return html`
        <div class="register-search__wrapper">
          <button class="register__button" @click=${this.navigateToRegister}>Registrieren</button>
          <button class="single__icon" aria-label="Suchschaltfläche" @click=${this.openFullscreenSearch}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
              <path d="M20.0264 17.2037L15.4282 12.5423C16.2095 11.2815 16.6629 9.79126 16.6629 8.19198C16.6629 3.66741 13.0446 0 8.5813 0C4.11803 0 0.5 3.66741 0.5 8.19198C0.5 12.7167 4.11786 16.3838 8.5813 16.3838C10.2982 16.3838 11.8885 15.8397 13.1971 14.9151L17.7405 19.5211C18.0562 19.8408 18.4701 20 18.8835 20C19.2973 20 19.7107 19.8408 20.0269 19.5211C20.6578 18.8808 20.6578 17.8438 20.0264 17.2037ZM8.5813 13.7297C5.56453 13.7297 3.11873 11.2506 3.11873 8.19232C3.11873 5.13407 5.56453 2.65476 8.5813 2.65476C11.5982 2.65476 14.0439 5.13407 14.0439 8.19232C14.0439 11.2506 11.5982 13.7297 8.5813 13.7297Z" fill="currentColor"/>
            </svg>
          </button>
        </div>
      `;
    }
    return null;
  }

  suggestionListTemplate() {
    return html`
      <div
        role="list"
        class="dropdown__positioner"
        @mouseenter=${this.handleDropdownMouseEnter}
        @mouseleave=${this.handleDropdownMouseLeave}
      >
      ${this.suggestions ? html`
        <ul class="menu ${this.show ? 'show' : null}">
          ${this.suggestions.map((suggestion: Suggest, i) => html`
            <li class="menu-item ${this.selectedMenuIndex === i ? 'highlight' : null}" @mousemove="${this.mouseOverOnItem(i)}">
              <a href="${suggestion.url}" class="${suggestion.category ? 'has__category' : ''}">
                <span class="image ${suggestion.category ? 'has__category' : null}">
                  <img src="data:${suggestion.type};base64,${suggestion.image}" />
                </span>
                ${this.renderOptionalCategoryDetail(suggestion.categoryDetail)}
                <span class="result">
                  <span class="suggest">${this.markInput(suggestion.suggest)}</span>
                  ${suggestion.label ? html`<span class="label">${suggestion.label}</span>` : null}
                </span>
              </a>
            </li>`)}
          <li><span>Suchvorschläge bereitgestellt durch ${this.brandName}</span></li>
        </ul>
      ` : ''}
      </div>
    `;
  }

  renderOptionalCategoryDetail(rawCategoryDetail: string) {
    if (rawCategoryDetail && rawCategoryDetail.length > 0) {
      try {
        const categoryDetails = rawCategoryDetail.replace(']', '°C').split(';');
        const temperature = categoryDetails[1];
        if (temperature && temperature.length > 0) {
          return html`
            <div class="suggest-temperature">
              <span>${temperature}</span>
            </div>
          `;
        } else {
          return null;
        }
      } catch (e) {
        Logger.logUnknownError(e, 'ui-multisearch');
        return null;
      }
    } else {
      return null;
    }
  }

  getHoverWrapperClasses(): string {
    let classes = 'hover__wrapper';

    if (this.className === 'fullscreen') {
      classes += ' fullscreen';
    } else {
      if (this.show) {
        classes += ' border';
      }
    }

    return classes;
  }

  mouseOverOnItem(idx: number) {
    return () => {
      this.selectedMenuIndex = idx;
    };
  }

  async requestSuggestions() {
    this.abortController.abort();
    this.abortController = new AbortController();
    const signal = this.abortController.signal;

    try {
      const response = await fetch(this.getRequestUri(), {signal});
      this.result = await response.json() as Suggestions;
    } catch (e) {
      Logger.logUnknownError(e, 'ui-multisearch');
    }
  }

  getRequestUri(): string {
    // example https://www.gmx.net/msuggest/v1?q=h&brand=webde&uiLang&device=tablet
    const uri = new URL(this.multiSuggest);
    uri.searchParams.append('q', this.userInput.trim());
    uri.searchParams.append('device', this.getCurrentViewportDevice());
    uri.searchParams.append('origin', this.currentOrigin + '_sg');
    uri.searchParams.append('count', '10');

    return uri.toString();
  }

  getCurrentViewportDevice(): string {
    if (window.innerWidth < tablet) {
      return 'mobile';
    } else if (window.innerWidth < desktop) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  isMobileViewport(): boolean {
    return window.innerWidth < tablet;
  }

  escapeRegExp(string: string) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  markInput(suggestText: string) {
    const searchTerm = this.userInput.trim();
    const suggestParts = suggestText.split(new RegExp(this.escapeRegExp(searchTerm), 'g'));
    const mark = html`<span style="font-weight:normal;">${searchTerm}</span>`;
    const retVal: TemplateResult[] = [];
    suggestParts.forEach((s, i) => {
      retVal.push(html`${s}`);
      if (i < suggestParts.length - 1) {
        retVal.push(mark);
      }
    });
    return retVal;
  }

  sendBrainTrackingEvent(clickName: string) {
    // we need a ping-pong to know when analytics is initialized
    PubSub.subscribeOnce('analytics:ServiceReady', () => {
      PubSub.publish('header:multisearch', {
        referrer: '&referrer=undefined',
        click1: '&click1=undefined',
        click2: '&click2=undefined',
        click3: '&click3=undefined',
        click4: '&click4=undefined',
        clickname: '&clickname=' + clickName
      });
    });
    PubSub.publish('multisearch:pingAnalyticsReady');
  }
}
